import { Outlet, Route } from "react-router-dom";

import { ErrorComponent, ThemedLayoutV2 } from "@refinedev/antd";
import { Authenticated } from "@refinedev/core";
import {
  CatchAllNavigate,
  NavigateToResource,
} from "@refinedev/react-router-v6";

import { CustomThemedSiderV2 } from "@/components/custom-sider";
import { Header } from "@/components/header";
import { AppTitle } from "@/components/layout/title";
import LazyLoad from "@/components/lazy-load";
import { Login } from "@/pages/login";

import { getCommonRoutes } from "./common.routes";
import { getCompanyModeRoutes } from "./company.routes";
import { getPartnerModeRoutes } from "./partner.routes";
import { getSuperAdminRoutes } from "./super_admin.routes";

function getOptionallyAuthedRoutes() {
  return (
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path="/referrals/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/referrals/create").then((module) => ({
                default: module.ReferralPage,
              }))
            }
            componentProps={{
              authed: false,
            }}
          />
        }
      />
      <Route
        path="/partner-signup/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/partner_inbound/page").then((module) => ({
                default: module.PartnerInboundPage,
              }))
            }
          />
        }
      />
      <Route
        path="/public/referral-links/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/referral-links/public").then((module) => ({
                default: module.ReferralLinkIntermediaryPage,
              }))
            }
          />
        }
      />
    </Route>
  );
}

function getAuthedRoutes({
  isSuperAdmin,
  mode,
}: {
  isSuperAdmin: boolean;
  mode: string;
}) {
  return (
    <Route
      element={
        <Authenticated
          key="authenticated-inner"
          fallback={<CatchAllNavigate to="/login" />}
        >
          <ThemedLayoutV2
            Title={AppTitle}
            Header={() => <Header sticky />}
            Sider={(props) => <CustomThemedSiderV2 {...props} fixed />}
          >
            <Outlet />
          </ThemedLayoutV2>
        </Authenticated>
      }
    >
      <Route
        index
        element={
          <NavigateToResource
            resource={mode === "Company" ? "dashboard" : "partner-dashboard"}
          />
        }
      />

      {/********************** Company View ********************/}
      {mode === "Company" ? getCompanyModeRoutes({ isSuperAdmin }) : null}
      {/********************** Partner View ********************/}
      {mode === "Partner" ? getPartnerModeRoutes({ isSuperAdmin }) : null}
      {/********************** Common Views ********************/}
      {getCommonRoutes({ isSuperAdmin })}
      {/********************** Super Admin View ********************/}
      {isSuperAdmin ? getSuperAdminRoutes() : null}
      {/********************** 404 Error View ********************/}
      <Route path="*" element={<ErrorComponent />} />
    </Route>
  );
}

function getLoginPages(mode: string) {
  return (
    <Route
      element={
        <Authenticated key="authenticated-outer" fallback={<Outlet />}>
          <NavigateToResource
            resource={mode === "Company" ? "dashboard" : "partner-dashboard"}
          />
        </Authenticated>
      }
    >
      <Route path="/login" element={<Login />} />
    </Route>
  );
}

export function getRoutes({
  isSuperAdmin,
  mode,
}: {
  isSuperAdmin: boolean;
  mode: string;
}) {
  return (
    <>
      {getLoginPages(mode)}
      {getAuthedRoutes({ isSuperAdmin, mode })}
      {getOptionallyAuthedRoutes()}
    </>
  );
}
