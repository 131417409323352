import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type AppModeContextType = {
  mode: string;
  setMode: (mode: string) => void;
};

export const AppModeContext = createContext<AppModeContextType>(
  {} as AppModeContextType
);

export const AppModeContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const localStorageData = localStorage.getItem("appMode");

  const [mode, setMode] = useState(localStorageData || "Company");

  useEffect(() => {
    window.localStorage.setItem("appMode", mode);
  }, [mode]);

  const setAppMode = (mode: string) => {
    if (mode === "Company") {
      setMode("Company");
    } else {
      setMode("Partner");
    }
    navigate("/");
    window.location.reload();
  };

  return (
    <AppModeContext.Provider
      value={{
        setMode: setAppMode,
        mode,
      }}
    >
      {children}
    </AppModeContext.Provider>
  );
};
