import {
  createContext,
  PropsWithChildren,
  startTransition,
  useCallback,
  useEffect,
  useState,
} from "react";

import { ConfigProvider, theme } from "antd";

const { darkAlgorithm, defaultAlgorithm, compactAlgorithm } = theme;

type ColorModeContextType = {
  colorMode: string;
  toggleColorMode: () => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
  {} as ColorModeContextType
);

const getInitialColorMode = () => {
  const colorModeFromLocalStorage = localStorage.getItem("colorMode");

  const isSystemPreferenceDark = window?.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;

  const systemPreference = isSystemPreferenceDark ? "dark" : "light";

  return colorModeFromLocalStorage || systemPreference;
};

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [colorMode, setMode] = useState(getInitialColorMode());

  const [motion, setMotion] = useState(true);

  useEffect(() => {
    window.localStorage.setItem("colorMode", colorMode);
    document.body.style.backgroundColor =
      colorMode === "light" ? "#FFFFFF" : "#000000";
  }, [colorMode]);

  const toggleColorMode = useCallback(() => {
    setMotion(false);
    setMode(colorMode === "light" ? "dark" : "light");
    startTransition(() => {
      setMotion(true);
    });
  }, [colorMode, setMode, setMotion]);

  return (
    <ColorModeContext.Provider
      value={{
        toggleColorMode,
        colorMode,
      }}
    >
      <ConfigProvider
        theme={{
          cssVar: true,
          token: {
            colorPrimary: "#F26D0F",
            fontFamily: "Inter",
            wireframe: true,

            ...(motion
              ? {}
              : {
                  motionDurationFast: "0",
                  motionDurationMid: "0",
                  motionDurationSlow: "0",
                }),
            colorBgLayout: colorMode === "light" ? "#FFFFFF" : "#000000",
          },
          components: {
            Card: {},
            Menu: {
              itemSelectedBg:
                colorMode === "light"
                  ? "HSL(190, 5%, 85%)"
                  : "HSL(190, 5%, 30%)", // Light grey for light mode, darker grey for dark mode
              itemSelectedColor: colorMode === "light" ? "#000000" : "#FFFFFF",
              itemHoverBg:
                colorMode === "light"
                  ? "HSL(190, 5%, 90%)"
                  : "HSL(190, 5%, 40%)", // Very light for hover in light mode, medium-dark grey for dark mode
              itemActiveBg:
                colorMode === "light"
                  ? "HSL(190, 5%, 80%)"
                  : "HSL(190, 5%, 25%)", // Slightly lighter for active in light mode, darker for dark mode
            },
            Tabs: {
              itemColor:
                colorMode === "light" ? "HSL(0, 0%, 50%)" : "HSL(0, 0%, 70%)",
              itemActiveColor:
                colorMode === "light" ? "HSL(0, 0%, 20%)" : "HSL(0, 0%, 90%)",
              itemHoverColor:
                colorMode === "light" ? "HSL(0, 0%, 40%)" : "HSL(0, 0%, 80%)",
              itemSelectedColor: colorMode === "light" ? "#000000" : "#FFFFFF",
              inkBarColor: colorMode === "light" ? "#000000" : "#FFFFFF",
              horizontalItemPadding: "8px 16px",
            },
            Button: {
              defaultShadow: "none",
              primaryShadow: "none",
            },
            Tree: {
              directoryNodeSelectedBg:
                colorMode === "light"
                  ? "HSL(190, 5%, 85%)"
                  : "HSL(190, 5%, 30%)", // Light grey for light mode, darker grey for dark mode
            },
          },
          algorithm: [
            colorMode === "light" ? defaultAlgorithm : darkAlgorithm,
            compactAlgorithm,
          ],
        }}
      >
        {children}
      </ConfigProvider>
    </ColorModeContext.Provider>
  );
};
