import { Outlet, Route } from "react-router-dom";

import LazyLoad from "@/components/lazy-load";
import { PlaceHolderPage } from "@/pages/placeholder";

const getPromptsRoutes = () => {
  return (
    <Route
      path="/prompts"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/super_admin/prompt/list").then((module) => ({
              default: module.PromptsListPage,
            }))
          }
        >
          <Outlet />
        </LazyLoad>
      }
    >
      <Route
        path="create"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/super_admin/prompt/create").then((module) => ({
                default: module.PromptsCreatePage,
              }))
            }
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/super_admin/prompt/edit").then((module) => ({
                default: module.PromptsEditPage,
              }))
            }
          />
        }
      />
    </Route>
  );
};

const getFeatureFlagsRoutes = () => {
  return (
    <Route
      path="/feature_flags"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/super_admin/feature_flag/list").then((module) => ({
              default: module.FeatureFlagsListPage,
            }))
          }
        >
          <Outlet />
        </LazyLoad>
      }
    >
      <Route
        path="create"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/super_admin/feature_flag/create").then(
                (module) => ({
                  default: module.FeatureFlagsCreatePage,
                })
              )
            }
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/super_admin/feature_flag/edit").then(
                (module) => ({
                  default: module.FeatureFlagsEditPage,
                })
              )
            }
          />
        }
      />
    </Route>
  );
};

const getDebugRoutes = () => {
  return (
    <Route
      path="debug"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/super_admin/debug/list").then((module) => ({
              default: module.DebugPage,
            }))
          }
        />
      }
    />
  );
};

const getCompanyRoutes = () => {
  return (
    <Route
      path="/superadmin/companies"
      element={
        <LazyLoad
          getComponent={() =>
            import("@/pages/super_admin/company/list").then((module) => ({
              default: module.CompanyListPage,
            }))
          }
        >
          <Outlet />
        </LazyLoad>
      }
    >
      <Route
        path="edit/:id"
        element={
          <LazyLoad
            getComponent={() =>
              import("@/pages/super_admin/company/edit").then((module) => ({
                default: module.CompanyEditModal,
              }))
            }
          />
        }
      />
    </Route>
  );
};

const getExperimentalRoutes = () => {
  return <Route path="/experimental" element={<PlaceHolderPage />} />;
};

export const getSuperAdminRoutes = () => {
  return (
    <>
      {/* Super admin */}
      {getPromptsRoutes()}
      {getFeatureFlagsRoutes()}
      {getDebugRoutes()}
      {getCompanyRoutes()}

      {/* Experimental */}
      {getExperimentalRoutes()}
    </>
  );
};
