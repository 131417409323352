import React, { useCallback, useMemo, useRef, useState } from "react";

import { DateField } from "@refinedev/antd";
import { useGo, useInfiniteList, useOne, useUpdate } from "@refinedev/core";

import { BellOutlined, CloseOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Flex,
  List,
  Popover,
  Space,
  theme,
  Typography,
} from "antd";

import useOnClickOutside from "@/hooks/useOnClickOutside";
import { Notification, UnreadCount } from "@/types";

const { Text } = Typography;

export const NotificationBell: React.FC = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [allRead, setAllRead] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setPopoverOpen(false));

  const { token } = theme.useToken();
  const go = useGo();

  const { data: unreadCount, refetch } = useOne<UnreadCount>({
    resource: "in_app_notifications",
    id: "unread_count",
  });

  const {
    data: notificationsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteList<Notification>({
    resource: "in_app_notifications",
    pagination: {
      pageSize: 5,
    },
    meta: {
      pagination: {
        mode: "server",
      },
    },
    queryOptions: {
      getNextPageParam: (lastPage: any) => {
        if (lastPage.total < 5) {
          return false;
        }
        return lastPage.pagination.current + 1 || 1;
      },
    },
  });

  const { mutateAsync: markAllAsRead } = useUpdate({
    resource: `in_app_notifications`,
    id: "mark_all_as_read",
    values: {},
    successNotification: false,
  });
  const onMarkAllNotificationsAsRead = useCallback(async () => {
    markAllAsRead().then(() => {
      setAllRead(true);
      refetch();
    });
  }, [markAllAsRead, refetch]);

  const { mutateAsync: markAsRead } = useUpdate({
    resource: `in_app_notifications`,
    id: "mark_as_read",
    successNotification: false,
  });
  const onMarkNotificationsAsRead = useCallback(
    async (notificationIds: string[]) => {
      markAsRead({
        values: {
          notification_ids: notificationIds,
        },
      }).then(() => refetch());
    },
    [markAsRead, refetch]
  );

  const notifications = useMemo(
    () => notificationsData?.pages.flatMap((page) => page.data) || [],
    [notificationsData?.pages]
  );

  const handleNotificationClick = async (item: Notification) => {
    if (!item.is_read) {
      await onMarkNotificationsAsRead([item.id]);
    }
    if (item.link) {
      if (item.link.startsWith("/")) {
        setPopoverOpen(false);
        go({
          to: item.link,
          type: "push",
        });
      } else {
        window.open(item.link, "_blank");
      }
    }
  };

  return (
    <Popover
      open={popoverOpen}
      content={
        <div ref={ref}>
          {notifications.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                padding: "20px",
                color: token.colorTextSecondary,
              }}
            >
              <InboxOutlined style={{ fontSize: token.fontSizeXL }} />
              <p>No new notifications</p>
            </div>
          ) : (
            <>
              <div
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    position: "sticky",
                    alignItems: "center",
                    backgroundColor: "var(--ant-color-bg-elevated)",
                    top: 0,
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text type={"secondary"}>
                    {notifications.length} Notifications
                  </Text>
                  <Space>
                    {isFetchingNextPage && (
                      <Text type={"secondary"}>Loading more...</Text>
                    )}
                    <Button
                      size={"small"}
                      type={"text"}
                      icon={<CloseOutlined />}
                      onClick={() => setPopoverOpen(false)}
                    />
                  </Space>
                </div>
                <List
                  dataSource={notifications}
                  renderItem={(item) => (
                    <NotificationItem
                      key={`${item.id}-${item.is_read}`}
                      notification={item}
                      handleNotificationClick={handleNotificationClick}
                      allRead={allRead}
                    />
                  )}
                />
              </div>
              <Flex
                vertical
                gap={8}
                style={{
                  marginTop: token.marginSM,
                }}
              >
                {hasNextPage && (
                  <Button
                    type="dashed"
                    block
                    onClick={() => fetchNextPage()}
                    loading={isFetchingNextPage}
                  >
                    Load More
                  </Button>
                )}
                <Button
                  type="primary"
                  block
                  onClick={() => onMarkAllNotificationsAsRead()}
                >
                  Mark All as Read
                </Button>
              </Flex>
            </>
          )}
        </div>
      }
      trigger="click"
    >
      <div
        onClick={() => setPopoverOpen((prev) => !prev)}
        style={{
          cursor: "pointer",
          userSelect: "none",
        }}
      >
        <Badge
          count={
            (unreadCount?.data.unread_count || 0) > 99
              ? "99+"
              : unreadCount?.data.unread_count
          }
        >
          <BellOutlined style={{ fontSize: token.fontSizeXL }} />
        </Badge>
      </div>
    </Popover>
  );
};

type NotificationItemProps = {
  notification: Notification;
  handleNotificationClick: (item: Notification) => void;
  allRead?: boolean;
};

const NotificationItem = ({
  notification,
  handleNotificationClick,
  allRead,
}: NotificationItemProps) => {
  const [isRead, setIsRead] = useState(notification.is_read || allRead);
  const read = useMemo(() => isRead || allRead, [allRead, isRead]);
  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      setIsRead(true);
      handleNotificationClick(notification);
    },
    [handleNotificationClick, notification]
  );

  return (
    <List.Item
      onClick={onClick}
      style={{
        padding: "8px 12px",
        borderBottom: "1px solid var(--ant-color-border-secondary)",
        cursor: "pointer",
      }}
    >
      <Flex align={"center"} gap={10}>
        <BellOutlined
          style={{
            fontSize: "16px",
            color: read ? "#b0b0b0" : "#1890ff",
          }}
        />
        <div style={{ flexGrow: 1 }}>
          <Text strong={!read}>{notification.text}</Text>
          <DateField
            value={notification.created_at}
            format="MMMM d, YYYY · h:mm A"
            style={{
              display: "block",
              marginTop: "4px",
              color: "#b0b0b0",
            }}
          />
        </div>
      </Flex>
    </List.Item>
  );
};
