import React, { useContext, useEffect } from "react";

import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import { useGetIdentity, useOne } from "@refinedev/core";

import { MoonOutlined, SunOutlined } from "@ant-design/icons";
import {
  Avatar,
  Flex,
  Layout as AntdLayout,
  Space,
  Switch,
  theme,
  Typography,
} from "antd";
import dayjs from "dayjs";

import { NotificationBell } from "@/components/in-app-notifications";
import { AppModeContext } from "@/contexts/app-context";
import { ColorModeContext } from "@/contexts/color-mode";
import { useUserPreference } from "@/providers/identity";
import type { Identity as User } from "@/types";
import { ICompanyConfiguration } from "@/types/company_configuration";

import "./no-switch-color.css";

const { Text } = Typography;
const { useToken } = theme;

const setDayJSLocale = async (region?: string) => {
  switch (region) {
    case "en-ca":
      await import("dayjs/locale/en-ca");
      dayjs.locale("en-ca");
      break;
    case "en-gb":
      await import("dayjs/locale/en-gb");
      dayjs.locale("en-gb");
      break;
    case "en":
    default:
      dayjs.locale("en");
  }
};

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  sticky,
}) => {
  const { token } = useToken();
  const { data: user } = useGetIdentity<User>();
  const preferences = useUserPreference();
  const { colorMode, toggleColorMode } = useContext(ColorModeContext);
  const { mode: productMode, setMode: setProductMode } =
    useContext(AppModeContext);

  useEffect(() => {
    if (
      preferences &&
      preferences.user_mode_state &&
      preferences.user_mode_state !== productMode
    ) {
      setProductMode(preferences.user_mode_state);
    }
  }, [preferences, productMode, setProductMode]);

  const { data } = useOne<ICompanyConfiguration>({
    resource: "company_settings",
    id: "config",
  });

  useEffect(() => {
    setDayJSLocale(data?.data?.region);
  }, [data?.data?.region]);

  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
    borderBottom: "1px solid var(--ant-color-border-secondary)",
  };

  if (sticky) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }

  return (
    <AntdLayout.Header style={headerStyles}>
      <Space size={"large"}>
        <Space size={"middle"}>
          <Switch
            checkedChildren="Partner"
            unCheckedChildren="Company"
            onChange={() =>
              setProductMode(productMode === "Partner" ? "Company" : "Partner")
            }
            defaultChecked={productMode === "Partner"}
          />
          <Switch
            checkedChildren={<MoonOutlined />}
            unCheckedChildren={<SunOutlined />}
            onChange={() => toggleColorMode()}
            defaultChecked={colorMode === "dark"}
          />
        </Space>
        <Flex align={"center"} style={{ paddingTop: 4 }}>
          <NotificationBell />
        </Flex>
        <Flex align={"center"} gap={8}>
          {user?.fullName && <Text strong>{user.fullName}</Text>}
          {user?.imageUrl && (
            <Avatar src={user?.imageUrl} alt={user?.fullName ?? ""} />
          )}
        </Flex>
      </Space>
    </AntdLayout.Header>
  );
};
