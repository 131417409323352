import { CustomField, FormStyles, Job, NullString } from "@/types";
import { Company } from "@/types/api/company";

export interface PermissionsRole {
  ID: number;
  name: string;
  type: string;
  policies: PermissionsPolicy[];
}

export interface PermissionsPolicy {
  ID: number;
  name: string;
  description: string;
  effect: string;
  action: string;
  resource: string;
}

export interface User {
  ID: number;
  person_id: number;
  person: Person;
  roles: PermissionsRole[];
}

export interface NullableDate {
  Time: Date;
  Valid: boolean;
}

export interface Person {
  ID?: number;
  email?: NullString;
  given_name?: string;
  family_name?: string;
  name?: string;
  company_id?: number;
  avatar_url?: string;
  company?: Company;
  current_jobs?: Job[];
  user?: User;
}

export type SingleCompanyModel = {
  company_id: number;
  company: Company;
};

export type SingleUserModel = SingleCompanyModel & {
  user_id: number;
  user: Person;
};

export interface Region {
  ID: number;
  Name: string;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  company_id: number;
  reference_table: string;
}

export const MODAL_ACTION = {
  CREATE: "create",
  EDIT: "edit",
} as const;

export type ModalAction = (typeof MODAL_ACTION)[keyof typeof MODAL_ACTION];

export enum REFERENCE_TYPE {
  PARTNER = "Partner",
  // TODO: Add other reference types here
}

export type ReferralPagePublicGetResponse = {
  company_name: string;
  company_logo: string;
  partner_name: string;
  partner_logo: string;
  partner_record_id: number;
  referral_page_id: number;
  details?: string;
  html_details?: string;
  form_styles?: FormStyles;
  custom_fields: CustomField[];
};

export interface Role {
  ID?: number;
  name: string;
  description: string;
  type: string;
  company_id?: number;
  created_at?: string;
  updated_at?: string;
}

export interface Action {
  ID?: number;
  name: string;
  description: string;
  company_id?: number;
  created_at?: string;
  updated_at?: string;
}

export interface PersonRoleResponse {
  id: number;
  email: string;
  given_name: string;
  family_name: string;
  name: string;
  avatar_url: string;
  role_names: string[];
}

export interface PolicyResponse {
  role_id: number;
  role_name: string;
  action_id: number;
  action_name: string;
  resource: string;
}

export interface PolicyRequest {
  role_id: number;
  action_id: number;
  resource: string;
}

export interface APIErrorResponse {
  response?: {
    data?: {
      error?: string;
    };
  };
  message?: string;
}
