import { Action, IResourceItem } from "@refinedev/core";

export const customTitleHandler = (options: {
  resource?: IResourceItem;
  action?: Action;
  params?: Record<string, string | undefined>;
  pathname?: string;
  autoGeneratedTitle: string;
}) => {
  let title = "Propel"; // Default title

  if (options?.resource?.meta?.label) {
    title = `${options?.resource?.meta?.label} | Propel`;
  }

  return title;
};
