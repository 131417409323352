import React from "react";

import {
  useGo,
  useNavigation,
  useRouterType,
  useTranslate,
} from "@refinedev/core";
import { RefineErrorPageProps } from "@refinedev/ui-types";

import { BuildTwoTone } from "@ant-design/icons";
import { Button, Result, Space, Typography } from "antd";

export const PlaceHolderPage: React.FC<RefineErrorPageProps> = () => {
  const translate = useTranslate();
  const { push } = useNavigation();
  const go = useGo();
  const routerType = useRouterType();

  return (
    <Result
      icon={<BuildTwoTone />}
      title="Coming Soon"
      extra={
        <Space direction="vertical" size="large">
          <Space>
            <Typography.Text>
              Sorry, the page you visited is still being built.
            </Typography.Text>
          </Space>
          <Button
            type="primary"
            onClick={() => {
              if (routerType === "legacy") {
                push("/");
              } else {
                go({ to: "/" });
              }
            }}
          >
            {translate("pages.error.backHome", "Back Home")}
          </Button>
        </Space>
      }
    />
  );
};
