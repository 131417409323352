import { useMemo } from "react";

import { SignIn } from "@clerk/clerk-react";
import { Layout, theme } from "antd";

const LATOUT_STYLES = {
  height: "100vh",
  justifyContent: "center",
  alignItems: "center",
};

export const Login: React.FC = () => {
  const { token } = theme.useToken();

  const appearance = useMemo(
    () => ({
      variables: {
        colorPrimary: token.colorPrimary,
        colorBackground: token.colorBgContainer,
        colorText: token.colorText,
        colorInputText: token.colorText,
        colorTextOnPrimaryBackground: token.colorText,
        colorTextSecondary: token.colorTextSecondary,
        colorInputBackground: token.colorBgElevated,
        fontFamily: token.fontFamily,
        colorDanger: token.colorError,
        colorSuccess: token.colorSuccess,
        colorWarning: token.colorWarning,
        colorNeutral: token.colorInfo,
      },
    }),
    [token]
  );

  return (
    <Layout style={LATOUT_STYLES}>
      <SignIn appearance={appearance} />
    </Layout>
  );
};
